@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

body {
  background: black;
  color: white;
  margin: 0;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1.1);
	}
}

@keyframes shift {
  0% {
    color: rgb(155, 255, 155);
  }
  34% {
    color: rgb(155, 255, 255);
  }
  100% {
    color: rgb(155, 155, 255);
  }
}

@keyframes glow {
  0% {
    background-color: rgb(155, 255, 155, .1);
  }
  34% {
    background-color: rgb(155, 255, 255, .1);
  }
  100% {
    background-color: rgb(155, 155, 255, .1);
  }
}

body.theme-x,
body.theme-y {
  background: white;
  color: black;
}

footer {
  padding: 1rem 4rem;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50rem;
  max-width: 75%;
  background: #ffffff10;
  border-radius: 1rem;
}

footer a {
  border-radius: 1rem;
  padding: .5rem;
  text-align: center;
  text-decoration: none;
  background: #fff722;
  color: black;
  width: auto;
  min-width: 8rem;
  margin: 0 0 0 2rem;
}

footer a:hover {
  background: dodgerblue;
}

.theme-x a {
  color: dodgerblue;
}

.theme-y a {
  color: black;
}

.topic,
.comment {
  padding: 1rem;
  background: #ffffff10;
}

.theme-x .comment {
  border: 1px solid #00000020;
  border-radius: .5rem;
  margin: .5rem;
}

.topic .message:not(.preview) {
  display: none;
}

.theme-x .message {
  display: block;
}

.comment.nested {
  background: none;
}

.topic.popular,
.comment .popular {
  display: block;
  background: #ffffff10;
  margin: .5rem;
  padding: 1rem;
  border-radius: 1rem;
  animation: shift 6s infinite alternate, glow 6s infinite alternate;
}

.theme-x .topic.popular,
.theme-x .comment .popular,
.theme-y .topic.popular,
.theme-y .comment .popular {
  animation: none;
  background: #ff660010;
}

.theme-x .topic.popular,
.theme-x .comment .popular {
  border-radius: .5rem;
  background: dodgerblue;
  color: white;
}

.theme-x .topic.popular a,
.theme-x .comment .popular a {
  color: white;
}

.theme-x .topic.popular .comment,
.theme-x .comment .popular .comment {
  background: white;
  color: black;
}

.theme-x .topic.popular .comment a,
.theme-x .comment .popular .comment a {
  color: dodgerblue;
}

.theme-x .topic.popular .comment a,
.theme-x .comment .popular .comment a {
  border: 1px solid #00000010;
}

.topic.unpopular > p,
.topic.unpopular:not(.animation-disabled) > p,
.comment > .unpopular > p,
.comment:not(.animation-disabled) > .unpopular > p {
  animation: none;
  opacity: .36;
}

.topic a,
.comment a {
  color: white;
}

.theme-x .topic a,
.theme-x .comment a {
  color: dodgerblue;
}

.theme-y .topic a,
.theme-y .comment a {
  color: black;
}

.topic:not(.animation-disabled) .author .item {
  font-size: 1.16em;
  animation: shift 6s infinite alternate;
}

.topic:not(.animation-disabled) > p,
.topic:not(.animation-disabled) > h2,
.comment:not(.animation-disabled) > span p,
.topic:not(.animation-disabled) .item.preview {
  animation: shift 6s infinite alternate;
  text-decoration: none;
}

.theme-y .topic {
  padding: .25rem;
}

.topic .author .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme-x .topic .author .item,
.theme-x .topic > p,
.theme-x .comment > span > p,
.theme-y .topic .author .item,
.theme-y .topic > p,
.theme-y .comment > span > p {
  animation: none;
}

.vote {
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
}

.small,
.upvote,
.downvote {
  display: block;
  font-size: .86em;
  opacity: .36;
}

.upvote:hover,
.downvote:hover {
  cursor: pointer;
}

.upvote:hover,
.downvote:hover {
  opacity: 1;
}

.topic > span {
  display: flex;
  gap: .5rem;
  align-items: center;
}

.feed > .topic:nth-of-type(odd) {
  background: #00000085;
}

.pricing {
  display: flex;
  justify-content: space-between;
  width: 90%;
  gap: 2rem;
  margin: 0 auto;
}

.pricing {
  margin-bottom: 4rem;
}

.pricing h2 {
  font-size: 1.2em;
}

.pricing ul > li {
  padding: .5rem;
}

.pricing ul > li:nth-of-type(odd) {
  background: #ffffff10;
}

.dagger {
  display: inline-block;
  margin-top: -.2em;
  vertical-align: top;
  animation: shift 6s infinite alternate;
}

.dagger,
.dagger + span {
  font-size: .86em;
  color: #ffffff50;
}

.faq {
  margin: 4rem 0;
}

.theme-x .feed > .topic {
  background: white;
  border: 1px solid #00000020;
}

.theme-y .feed > .topic:nth-of-type(odd) {
  background: #00000007;
}

.feed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #00000085;
  margin: 0;
}

.theme-x .feed,
.theme-x .feed li {
  list-style: none;
  margin: 0;
  padding: 0;
  background: none;
}

.theme-x .feed li {
  margin: .5rem 0;
  padding: 1rem;
  border-radius: .5rem;
}

.theme-y .feed,
.theme-y #news > article {
  background: #f6f6ef;
  color: black;
}

.theme-y #news > article {
  margin-top: 0;
}

.feed,
.feed > li,
.feed li {
  margin: 0;
  padding: 0;
  list-style-type: decimal;
}

.feed > li {
  padding: 1rem;
}

.feed .topic .small em {
  margin-left: 1.5em;
}

.feed.no-list,
.feed.no-list > li,
.feed.no-list li {
  list-style: none;
}

.author {
  display: flex;
  font-size: .86em;
  align-items: center;
  gap: .25rem;
}

.author a {
  text-decoration: none;
}

.author a:hover {
  text-decoration: underline;
}

.author em {
  opacity: .36;
}

.author h6 {
  margin: 0;
  font-weight: normal;
}

.author .vote {
  margin-right: .5rem;
}

.author .upvote,
.author .downvote {
  font-size: 1em;
}

.pulse {
  display: block;
	transform: scale(1);
	animation: pulse 1s infinite alternate;
}

.controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.controls.full {
  width: 100%;
  justify-content: space-between;
}

.control {
  display: inline-block;
  font-size: .86em;
  margin: 0 0 1rem;
  padding: 0;
  width: auto;
  background: none;
}

.control:hover {
  background: none;
  text-decoration: underline;
}

.theme-x .control {
  color: dodgerblue;
}

.theme-x .popular .control {
  color: white;
}

.theme-y .control {
  color: black;
}

.form {
  position: relative;
  background: white;
  color: #121212;
  box-shadow: 0 0 10rem dodgerblue;
  width: 75%;
  padding: 1rem;
  border-radius: 1rem;
}

.form.page {
  width: 32rem;
  background: transparent;
  box-shadow: none;
  border: 1px solid #ffffff25;
  margin: 1rem 0;
  border-radius: .5rem;
  color: white;
}

.form.page h2 {
  color: #ffffff75;
}

.form.page p {
  font-weight: 800;
}

.form.page input,
.form.page textarea {
  background: #ffffff25;
  border-radius: .5rem;
  color: white;
}

.form.page input[type="checkbox"] {
  position: relative;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  color: black;
}

.form.page input[type="number"] {
  width: 6rem;
  text-align: right;
  padding-right: .5rem;
}

.form.page input[type="checkbox"]:checked {
  background: white;
}

.form.page input[type="checkbox"]:checked:before {
  content: "✔";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 2rem;
  border-radius: .5rem;
}

.form.page button {
  display: inline-block;
  text-align: left;
  width: auto;
  background: white;
  color: black;
  border-radius: .5rem;
}

.feed + button {
  margin: 1rem 2.5rem;
}

.cta-button {
  max-width: 12rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-button,
.cta-button > span {
  color: black;
}

.landing {
  display: flex;
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.landing .page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.landing .page a:not(.cta-button) {
  color: white;
}

.landing .page > section {
  width: 100%;
  max-width: 64rem;
}

.landing .page > section:first-of-type {
  padding-top: 1rem;
  margin-top: 1rem;
}

.landing .page > section:first-of-type > h3 {
  margin: 1rem 0;
  font: 600 normal 3em "Outfit Medium", sans-serif;
  line-height: 1em;
  text-align: center;
}

.landing .page > section h4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
}

.landing .page > section > h4:last-of-type {
  margin-bottom: 1rem;
}

.landing .page > section > img {
  display: block;
  margin: 2.5rem auto;
  border-radius: 1rem;
  max-width: 48rem;
  box-shadow: 0 0 3rem #1e90ff50;
}

.landing .page .cta {
  margin: 4rem 0;
}

.landing .page .cta p {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
}

.landing .page .cta > div {
  display: flex;
  padding-top: 1rem;
  justify-content: center;
}

.landing .page .cta-button {
  display: flex;
  padding: .75rem 2.25rem;
  margin-top: 2rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  border-radius: 1rem;
  text-decoration: none;
}

.landing .page .cta-button span {
  width: auto;
}

#news {
  flex-direction: column;
  width: 90%;
  min-width: 50rem;
  max-width: 64rem;
  margin: 0 auto;
}

.theme-x #news {
  width: 50rem;
}

#news .breadcrumbs {
  position: relative;
  height: 2rem;
  background: #fff722;
  border-radius: 1px;
  margin: 0;
}

#news .breadcrumbs a {
  color: inherit;
}

#news .breadcrumbs .nav .theme {
  display: none;
}

.theme-x #news .breadcrumbs,
.theme-x #news .breadcrumbs img {
  background: dodgerblue;
  color: white;
}

.theme-x #news .breadcrumbs {
  height: 3rem;
  padding: .5rem;
  border-radius: .5rem;
  overflow: hidden;
}

.theme-y #news .breadcrumbs,
.theme-y #news .breadcrumbs img {
  background: #ff6600;
}

#news .breadcrumbs strong {
  font-weight: 800;
}

#news .breadcrumbs img {
  background: #fff722;
  object-fit: contain;
  object-position: center;
  width: 2rem;
  padding: .25rem;
}

#news .breadcrumbs a,
#news .breadcrumbs button,
#news .breadcrumbs {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: .5rem;
}

#news .breadcrumbs a:hover,
#news .breadcrumbs button:hover {
  text-decoration: underline;
}

#news .breadcrumbs .auth-button {
  background: none;
  font: inherit;
  padding: 0;
  font-size: .92em;
  width: auto;
  position: absolute;
  right: 1rem;
}

#news .breadcrumbs a {
  text-decoration: none;
  height: 100%;
}

#news .breadcrumbs .subheading {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.theme-x #news .breadcrumbs a,
.theme-x #news .breadcrumbs .subheading {
  color: white;
}

.theme-x #news .breadcrumbs .auth-button {
  background: white;
  border-radius: 100vw;
  height: 1.5rem;
  padding: 0 .5rem;
  color: dodgerblue;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: .92em;
}

#news .breadcrumbs .nav {
  display: flex;
  gap: 1.5rem;
  margin-left: 1.5rem;
  max-height: 100%;
}

#news .breadcrumbs .nav a,
#news .breadcrumbs .nav button,
.profile button,
.feed + button {
  background: none;
  font: inherit;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: .92em;
}

.profile button,
.feed + button {
  background: none;
  color: white;
  width: auto;
  margin: 1rem 0;
  padding: 0;
}

.feed + button {
  margin: 1rem 2.5rem;
}

.profile button:hover,
.feed + button:hover {
  background: none;
  text-decoration: underline;
}

#news .breadcrumbs .nav + a {
  position: absolute;
  right: 1rem;
}

#news .breadcrumbs .nav a:not(.home):hover,
#news .breadcrumbs .nav button:hover {
  background: none;
  color: dodgerblue;
}

.theme-y #news .breadcrumbs .nav button:hover {
  color: white;
}

#news .breadcrumbs .nav li {
  display: flex;
  align-items: center;
  justify-content: center;
}

#news .breadcrumbs .nav li > p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .25rem;
  border-radius: 100vw;
  color: dodgerblue;
}

.theme-x #news .breadcrumbs .nav button,
.theme-x #news .breadcrumbs .nav li > p,
.theme-y #news .breadcrumbs .nav li > p {
  color: white;
}

#news .breadcrumbs .nav li .small {
  font-size: .86em;
  opacity: .75;
  display: flex;
  align-items: center;
  justify-content: center;
}

#news > ul,
#news > article {
  margin: 1rem 0;
  padding: .25rem;
}

#overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #00000075;
  opacity: 0;
  transition: opacity .5s ease;
}

#overlay.show {
  opacity: 1;
}

#overlay > .form {
  max-width: 34rem;
  transition: opacity .5s ease, margin-top .5s ease;
  margin-top: 10vh;
  opacity: 0;
}

#overlay.show > .form {
  margin-top: 0;
  opacity: 1;
}

@media (max-width: 64rem) {
  #news {
    min-width: 0;
    width: 100%;
    padding: 1rem;
  }

  #news .breadcrumbs .nav,
  #news .breadcrumbs .auth-button {
    margin: 0;
    font-size: .86em;
    gap: 0.25rem;
  }

  #news .breadcrumbs .nav > li:last-of-type,
  #news .breadcrumbs strong,
  #news .topic .author .info span {
    display: none;
  }

  #news .breadcrumbs .subheading {
    max-width: 50%;
  }

  .landing {
    padding: .5rem;
  }

  .feed,
  .feed > li,
  .feed li {
    margin: .5rem;
  }

  .feed .topic .author {
    display: block;
  }

  .feed .topic .author .info {
    justify-content: flex-start;
  }

  .form,
  .form.page {
    width: 95%;
  }

  .feed + button {
    margin: 1rem;
  }

  .pricing {
    flex-direction: column;
    width: 100%;
  }

  footer {
    padding: 1rem;
    min-width: 100%;
    width: 100%;
  }
}

@media (min-width: 64rem) {
  .landing {
    padding: 6rem;
  }

  .landing .page {
    flex-direction: column;
  }

  .landing .page .cta-button {
    margin-top: 0;
  }

  .landing .page .cta-button span {
    width: 100%;
  }
}
