body {
  background: black;
  color: white;
}

body {
  margin: 0;
  font: 400 normal 15px/23px "Outfit Light", sans-serif;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

code {
  font-family: monospace;
  font-size: .86em;
  font-weight: 800;
}

input[disabled],
button[disabled],
textarea[disabled] {
  opacity: .5;
  font-style: italic;
  cursor: not-allowed;
}

button,
input,
textarea {
  display: block;
  cursor: pointer;
  width: 100%;
  background: #000000;
  color: white;
  padding: 1rem;
  appearance: none;
  border-radius: 1.2rem;
  border: none;
  margin: 1rem 0;
  font-family: "Outfit Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color .1s linear, color .1s linear;
  outline: none;
  resize: none;
}

input,
textarea {
  cursor: auto;
  background: #00000025;
  color: #121212;
}

button:hover {
  background-color: dodgerblue;
  color: white;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: .75em;
  margin: 0 0 1rem;
  opacity: .5;
}

a {
  color: black;
}

strong {
  font-weight: 800;
}