.card-list {
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: .75rem;
  align-items: center;
  width: 100%;
}

.landing .page .card-list a.cta-button {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  padding: .75rem;
  color: #64748b;
  font-size: .875rem;
  line-height: 1.25rem;
  text-align: center;
  background: transparent;
  width: 90%;
  animation: shift 6s infinite alternate, glow 6s infinite alternate;
}

@media (min-width: 900px) {
  .card-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
}
