.card {
  overflow: hidden;
  padding: .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
  background: #0f172a;
  border-radius: 1rem;
  height: 7rem;
}

.card h2 {
  margin-top: 0;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: shift 6s infinite alternate;
}

.card-content {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .92em;
}

.card-details .truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-preview-img {
  margin-right: 0.75rem;
  max-width: 2rem;
  max-height: 2rem;
  background-color: white;
  padding: .25rem;
  border-radius: .5rem;
}

.card-preview-img img {
  max-width: 1.5rem;
  max-height: 1.5rem;
  object-fit: contain;
  object-position: center center;
}

.card-details {
  margin: 0;
  width: 100%;
  min-width: 0;
  font-weight: 300;
  text-align: left;
}

.card-details p {
  margin: 0;
}

.card-details em {
  opacity: .5;
}
