.header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem;
  border-bottom: 1px solid #1e293b;
  width: 100%;
  max-width: 64rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  align-items: center;
}

.header .branding {
  display: block;
  color: #ffffff;
}

.header nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.header a {
  color: white;
  text-decoration: none;
}

.header .login-cta-link {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: flex-end;
}

.header .user {
  font-size: 0.75rem;
  line-height: 1rem;
  opacity: 0.5;
  text-align: right;
}

@media (max-width: 64rem) {
  .header .login-cta-link {
    margin-top: 0;
    margin-bottom: 0;
  }
}
